import React from "react"
import { makeStyles } from "@material-ui/core/styles/index"
import {
  Container,
  Typography,
  Grid,
  CardContent,
  Card,
} from "@material-ui/core/index"
import { Link } from "gatsby"
import faceLiftStripe from "../../images/faceLiftStripe.png"
import fillersStripe from "../../images/fillersStripe.png"
import correctiveStripe from "../../images/correctiveStripe.png"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(6),
    },
    padding: theme.spacing(0, 12, 0, 12),
    ["@media (max-width:780px)"]: {
      // eslint-disable-line no-useless-computed-key
      padding: theme.spacing(0, 4, 0, 6),
    },
  },
  card: {
    position: "relative",
    textTransform: "upperCase",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: "rgba(0,0,0,0.5)",
    // background: "#F8F4F5",
    minHeight: "80px",
    padding: "2rem",
    borderRadius: "10px",
    ["@media (min-width:780px)"]: {
      fontSize: "4rem",
      minHeight: "300px",
    },
    ["@media (max-width:780px)"]: {
      marginTop: "0rem",
      marginBottom: "0rem",
    },
    cursor: "pointer",
    "&:hover": {
      textDecoration: "none",
      background: "rgba(255, 255, 255, 0.3)",
    },
  },
  titleBig: {
    opacity: "1.0",
    fontSize: "1.4rem !important",
    color: "white",
    paddingBottom: "10px",
    whiteSpace: "pre-line",
  },
  titleSmall: {
    opacity: "1.0",
    fontSize: "1rem !important",
    // color: "#464A46",
    color: "white",
  },
  cardCorner: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100px",
    height: "100px",
    backgroundColor: "transparent",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  fillersStripe: {
    backgroundImage: `url(${fillersStripe})`,
  },
  faceLiftStripe: {
    backgroundImage: `url(${faceLiftStripe})`,
  },
  correctiveStripe: {
    backgroundImage: `url(${correctiveStripe})`,
  },
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
  },
}))

const ServiceCard = ({ intl }) => {
  const services = [
    {
      title: intl.formatMessage({ id: "services.nonSurgical.title" }),
      description: intl.formatMessage({
        id: "services.nonSurgical.description",
      }),
      className: "fillersStripe",
      link: "/fillers-and-btx/",
    },
    {
      title: intl.formatMessage({ id: "services.surgical.title" }),
      description: intl.formatMessage({ id: "services.surgical.description" }),
      className: "faceLiftStripe",
      link: "/treatments/",
    },
    {
      title: intl.formatMessage({ id: "services.corrections.title" }),
      description: intl.formatMessage({
        id: "services.corrections.description",
      }),
      className: "correctiveStripe",
      link: "/treatments/",
    },
  ]

  const classes = useStyles()
  return (
    <Container maxWidth="xl" component="main" className={classes.container}>
      <Grid container spacing={2}>
        {services.map((service) => (
          <Grid item xs={12} md={4} key={service.title}>
            <Link
              to={
                intl.locale === "nl"
                  ? service.link
                  : `/${intl.locale}${service.link}`
              }
              className={classes.link}
            >
              <Card className={classes.card}>
                <div
                  className={`${classes[service.className]} ${
                    classes.cardCorner
                  }`}
                ></div>
                <CardContent>
                  <Typography
                    component="h2"
                    variant="h3"
                    className={classes.titleBig}
                  >
                    {service.title}
                  </Typography>

                  <Typography
                    component="li"
                    variant="subtitle1"
                    className={classes.titleSmall}
                  >
                    {service.description}
                  </Typography>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default injectIntl(ServiceCard)
