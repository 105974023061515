import Container from "@material-ui/core/Container"
import React from "react"
import { makeStyles } from "@material-ui/core"
import { Typography } from "@material-ui/core/index"
import { injectIntl } from "gatsby-plugin-intl"

const useStyles = makeStyles(theme => ({
  missionContainer: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#F8F4F5",
    justifyContent: "center",
    alignItems: "center",
  },
  missionHeader: {
    color: "#9D989A",
    padding: "2em 0",
  },
  missionIngressContainer: {
    justifyContent: "center",
    color: "#9D989A",
    alignSelf: "center",
    alignItems: "center",
    maxWidth: "700px",
  },
  missionIngress: {
    color: "#9D989A",
    // fontSize: "1.1rem",
    paddingBottom: "6em",
  },
  missionText: {
    fontSize: "1.1rem",
  },
}))

const Mission = ({ intl }) => {
  const classes = useStyles()
  return (
    <Container
      maxWidth="xl"
      component="main"
      className={classes.missionContainer}
    >
      <Container
        maxWidth="xl"
        component="main"
        className={classes.missionHeader}
      >
        <Typography align="center" variant="h4">
          {intl.formatMessage({ id: "mission.title" })}
        </Typography>
      </Container>
      <div className={classes.missionIngressContainer}>
        <div className={classes.missionIngress}>
          <Typography variant="subtitle1" className={classes.missionText}>
            {intl.formatMessage({ id: "mission.description" })}
            {/* <br/><Link href="/mission">Read more..</Link> */}
          </Typography>
        </div>
      </div>
    </Container>
  )
}

export default injectIntl(Mission)
