import Container from '@material-ui/core/Container'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import {Typography} from '@material-ui/core/index'
import { injectIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles(theme => ({
    missionContainer: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#F8F4F5',
        justifyContent: 'center',
        alignItems: 'center',
    },
    missionHeader: {
        color: '#9D989A',
        paddingTop: '6em',
        paddingBottom: '3em',
    },
    missionIngressContainer: {
        justifyContent: 'center',
        color: '#9D989A',
        alignSelf: 'center',
        alignItems: 'center',
        maxWidth: '900px',
    },
    missionIngress: {
        color: '#9D989A',
    },
}));

const weAre = ({intl}) => {
  const classes = useStyles();
  return (
      <Container maxWidth="xl" component="main" className={classes.missionContainer}>
          <Container maxWidth="xl" component="main" className={classes.missionHeader}>
              <Typography align="center" variant="h4">{intl.formatMessage({id: "weAre.title"})}</Typography>
          </Container>
          <div className={classes.missionIngressContainer}>
              <div className={classes.missionIngress}>
              </div>
          </div>
      </Container>
  );
}

export default injectIntl(weAre);
